const Humburger = document.getElementById('js-toggle');
const Navigation = document.getElementById('js-nav');

Humburger.onclick = () => {
  Humburger.classList.toggle('c-toggle--active');
  Navigation.classList.toggle('l-navigation--opened');
}


const headH = 80;
const smoothScroll = (target, offset) => {
  let toY;
  let nowY = window.pageYOffset;

  const divisor = 8;
  const range = (divisor / 2) + 1;
  const targetRect = target.getBoundingClientRect();
  const targetY = targetRect.top + nowY - offset;

  (function () {

    let thisFunc = arguments.callee;
    toY = nowY + Math.round((targetY - nowY) / divisor);
    window.scrollTo(0, toY);
    nowY = toY;

    if (document.body.clientHeight - window.innerHeight < toY) {
      window.scrollTo(0, document.body.clientHeight);
      return;
    }

    if (toY >= targetY + range || toY <= targetY - range) {
      window.setTimeout(thisFunc, 10);
    } else {
      window.scrollTo(0, targetY);
    }

  })();
};


const smoothOffset = headH; 
const links = document.querySelectorAll('a[href*="#"]');

for (let i = 0; i < links.length; i++) {

  links[i].addEventListener('click', (e) => {

    const href = e.currentTarget.getAttribute('href');
    const splitHref = href.split('#');
    const targetID = splitHref[1];
    const target = document.getElementById(targetID);

    Humburger.classList.remove('c-toggle--active');
    Navigation.classList.remove('l-navigation--opened');
    smoothScroll(target, smoothOffset);

    return false;

  });
}
